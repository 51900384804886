<template lang="html">
  <v-container fluid>
    <v-row justify="end">
      <v-col cols="12" sm="6" md="9">
        <h3 class="headline mb-0">{{ $tc('label.estrutura_pai', 1) }}</h3>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          id="estrutura_usuario_pai"
          name="estrutura_usuario_pai"
          v-model="nomeEstruturaUsuarioPai"
          :items="listaUsuarioPai"
          :search-input.sync="buscaListaUsuarioPai"
          @click:append="triggerEstruturaPai"
          @click.native="buscarEstruturaUsuarioAutocomplete"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          item-text="nomEstruturaUsuario"
          item-value="id"
          :label="`${$tc('label.estrutura_usuario', 1)}`"
          :placeholder="$tc('label.digite_para_buscar', 1)"
          @change="setEstruturaUsuarioPai"
          clearable
          return-object>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      disable-pagination
      :items-per-page="-1"
      :headers="headers"
      :items="tableData"
      :no-data-text="$t('label.tabela_sem_conteudo')">
      <template v-slot:item.nome="{ item }">
        {{ item.nomEstruturaUsuario }}
      </template>
      <template v-slot:item.estrutura_pai="{ item }">
        {{ item.nomeEstruturaUsuarioPai }}
      </template>
      <template v-slot:item.verba_estrutural="{ item }">
        <v-switch
            class="verbas-estruturais"
            @change="inativarOutros(item)"
            v-model="item.verbaEstrutural"
            color="primary"
            id="estruturaPaiTabela_verbaEstrutural"
            name="estruturaPaiTabela_verbaEstrutural"/>
      </template>
      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom v-if="!somenteLeitura">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click.stop="removeRowItem(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.remover') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <confirm
      ref="dialogConfirmaRemover"
      :message="$t('message.deseja_remover_estrutura_pai')"
      :persistent="true"
      @agree="removerEstruturaUsuarioPai">
    </confirm>
  </v-container>
</template>
<script type="text/javascript">
import { prepareResponseData } from '../../common/functions/http';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    estruturasPais: Array,
    idEstruturaUsuario: Number,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Confirm,
  },
  data() {
    return {
      buscaListaUsuarioPai: null,
      listaUsuarioPai: [],
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      timeout: 0,
      tableData: [],
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: false },
        { text: this.$tc('label.estrutura_pai', 2), value: 'estrutura_pai', sortable: false },
        { text: this.$tc('label.verba_estrutural', 2), value: 'verba_estrutural', sortable: false },
        { text: '', value: 'acoes', sortable: false },
      ],
      currentEditItem: null,
      nomeEstruturaUsuarioPai: '',
    };
  },
  watch: {
    buscaListaUsuarioPai(val) {
      if (this.timeoutUsuarioPai) {
        window.clearTimeout(this.timeoutUsuarioPai);
      }
      this.timeoutUsuarioPai = window.setTimeout(() => {
        if (val != null) this.buscarEstruturaUsuarioAutocomplete(val);
      }, 500);
    },
    estruturasPais(valor) {
      this.montarTableData(valor);
    },
  },
  methods: {
    prepareResponseData,
    getServicos() {
      return this.tableData;
    },
    removeRowItem(row) {
      this.currentEditItem = row;
      this.$refs.dialogConfirmaRemover.open();
    },
    removerEstruturaUsuarioPai() {
      this.$refs.dialogConfirmaRemover.close();
      const index = this.tableData.indexOf(this.currentEditItem);
      if (index > -1) {
        this.tableData.splice(index, 1);
      }
    },
    setEstruturaUsuarioPai(hit) {
      if (!hit) return;
      if (hit.estruturaUsuarioPai) {
        hit.estruturaUsuarioPai = JSON.parse(hit.estruturaUsuarioPai);
        hit.nomeEstruturaUsuarioPai = this.montarNomes(hit.estruturaUsuarioPai);
      }
      this.tableData.push(hit);
      window.setTimeout(() => {
        this.nomeEstruturaUsuarioPai = '';
      });
      this.listaUsuarioPai = [];
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      if (cb) {
        cb();
      }
    },
    buscarEstruturaUsuarioAutocomplete(busca = null) {
      const ids = [];
      if (this.idEstruturaUsuario) {
        ids.push(this.idEstruturaUsuario);
      }
      this.tableData.forEach((value) => {
        ids.push(value.id);
      });

      const param = {
        filter: busca,
        size: 20,
        page: 0,
        idsEstruturaUsuarioNotIn: ids,
      };

      this.estruturaUsuarioResources.listarAutocomplete(param).then((response) => {
        this.listaUsuarioPai = Object.assign([], response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    montarNomes(estruturaUsuarioPai) {
      if (!estruturaUsuarioPai) return '';
      return estruturaUsuarioPai.map((u) => u.nomEstruturaUsuario).join(', ');
    },
    getEstruturasPais() {
      return this.tableData;
    },
    inativarOutros(item) {
      const index = this.tableData.indexOf(item);
      const row = this.tableData[index];
      if (row.verbaEstrutural) {
        this.tableData.forEach((value, i) => {
          if (index !== i) value.verbaEstrutural = false;
        });
      }
      this.$forceUpdate();
    },
    montarTableData(valor) {
      if (valor && valor.length) {
        this.tableData = [...valor];
        this.tableData.forEach((e) => {
          e.nomeEstruturaUsuarioPai = this.montarNomes(e.estruturaUsuarioPai);
        });
      }
    },
    triggerEstruturaPai() {
      this.triggerClick('estrutura_usuario_pai');
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
  },
};
</script>
<style>
  .verbas-estruturais .v-input__slot {
    margin-bottom: 0px;
    margin-top: 8px;
  }
</style>
