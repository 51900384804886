<template lang="html">
  <div>
    <v-form ref="form" class="pa-3" lazy-validation>
      <v-card class="mb-2">
        <v-container fluid>
          <h2>{{ $tc('label.estrutura_usuario', 1) }}</h2>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                class="CalendarioForm-data__text"
                :rules="[rules.required]"
                v-model="estruturaUsuario.nomEstruturaUsuario"
                :label="`${$t('label.nome')} *`"
                :counter="50"
                maxlength="50">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container fluid>
          <h2>{{ $tc('title.configuracao_visao', 1) }}</h2>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="4">
              <v-select
                id="acesso_filtro_visao"
                name="acesso_filtro_visao"
                :items="filtrosVisoes"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.filtro_visao', 1)}`"
                v-model="estruturaUsuario.visaoExtensao"
                item-text="entidadeEstrangeira"
                item-value="idNivelExtensao"
                multiple
                chips
                clearable>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                id="acesso_tipo_acao_usuario_usuarios"
                name="acesso_tipo_acao_usuario_usuarios"
                :items="tiposAcao"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.tipo_acao', 1)}`"
                v-model="estruturaUsuario.tiposAcao"
                item-text="nome"
                item-value="id"
                multiple
                chips
                clearable
                return-object
                :rules="[rules.required]">
                <template
                  slot="selection"
                  slot-scope="data">
                  <v-chip color="accent" text-color="white">{{ data.item.nome }}</v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-switch
                :label="`${$tc('label.estrutura_usuario', 2)}`"
                id="estruturaUsuario_indHierarquia"
                name="estruturaUsuario_indHierarquia"
                color="primary"
                v-model="estruturaUsuario.indHierarquia"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <estrutura-pai-tabela ref="estruturaPai"
                              :somente-leitura="false"
                              :estruturas-pais="estruturaUsuario.estruturaUsuarioPais"
                              :id-estrutura-usuario="idEstruturaUsuario">
        </estrutura-pai-tabela>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
            <v-btn v-if="!somenteLeitura" @click.native="validarForm" color="primary">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-dialog v-model="dialog.open" width="350">
      <v-card>
        <v-card-title>
          {{ dialog.title }}
        </v-card-title>
        <v-card-text>
          {{ dialog.content }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog(dialog)">
            {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn color="primary" text @click="closeDialog(dialog, dialog.action)">
            {{ $t('label.sim') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EstruturaPaiTabela from './EstruturaPaiTabela';

export default {
  name: 'EstruturaUsuarioForm',
  components: {
    EstruturaPaiTabela,
  },
  props: {
    copia: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buscaTiposVerba: null,
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        open: false,
        action() {},
      },
      estruturaUsuario: {
        nomEstruturaUsuario: '',
        indHierarquia: false,
        indRepresentante: false,
        indCarteira: false,
        indRateio: false,
        indExtensao: false,
        estruturaUsuarioPais: [],
        visaoExtensao: [],
      },
      idEstruturaUsuario: null,
      tiposVerba: [],
      tiposAcao: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getDivisaoMetadado',
    ]),
    filtrosVisoes() {
      const mapaEntidades = this.getDivisaoMetadado
        ? this.getDivisaoMetadado.mapaEntidades : [];
      const visoes = [];
      Object.keys(mapaEntidades).forEach((k) => {
        if (mapaEntidades[k].depObrigatoria) {
          visoes.push(mapaEntidades[k]);
        }
      });
      return visoes;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setDivisaoMetadado',
    ]),
    popularMetadados() {
      if (this.getDivisaoMetadado) {
        return;
      }
      this.setDivisaoMetadado({
        resource: this.resourceMetadado.listarDivisao,
      });
    },
    validarForm() {
      if (this.$refs.form.validate()) {
        if (!this.estruturaUsuario.indHierarquia
          && !this.estruturaUsuario.indExtensao
          && !(this.estruturaUsuario.visaoExtensao
              && this.estruturaUsuario.visaoExtensao.length)
          && !this.estruturaUsuario.indCarteira) {
          this.$toast(this.$t('message.estrutura_usuario_definicao_obrigatoria'));
          return;
        }
        this.openSaveDialog();
      }
    },
    buscarTiposAcao() {
      this.tipoAcaoResource.buscarAtivos()
        .then((response) => {
          this.tiposAcao = Object.assign([], response.data);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarEstrutura() {
      const id = this.idEstruturaUsuario;
      this.estruturaUsuarioResources.obter({ id })
        .then((res) => {
          this.estruturaUsuario = res.body;
          this.estruturaUsuario.tiposVerba = this.estruturaUsuario.tiposVerba
            ? this.estruturaUsuario.tiposVerba : this.estruturaUsuario.tipoVerba;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancel() {
      if (this.somenteLeitura) {
        this.voltar();
        return;
      }
      this.setDialogContent(this.$t('message.deseja_voltar'), this.voltar, this.dialog.from);
      this.openDialog(this.dialog);
    },
    openSaveDialog() {
      const isEditing = this.$route.params.id;
      const content = isEditing
        ? this.$t('message.deseja_salvar_edicao', { text: this.$tc('label.estrutura_usuario', 1).toLowerCase() })
        : this.$t('message.deseja_salvar', { text: this.$tc('label.estrutura_usuario', 1).toLowerCase() });
      this.setDialogContent(content, this.save, this.dialog.from);
      this.openDialog(this.dialog);
    },
    openDialog(dialog) {
      dialog.open = true;
    },
    setDialogContent(content, action, from) {
      this.dialog.content = content || this.$t('message.salvar_novo_registro');
      this.dialog.action = action || this.save;
      this.dialog.from = from || this.dialog.from;
    },
    save() {
      const estrutura = this.montarEstrutura();
      if (this.$route.params.id) {
        return this.requestAtualizar(estrutura,
          this.estruturaUsuarioResources.atualizar);
      }
      return this.requestGravar(estrutura, this.estruturaUsuarioResources.gravar);
    },
    montarEstrutura() {
      const nome = this.estruturaUsuario
        .nomEstruturaUsuario.replace(/\t/g, '');
      const estrutura = {
        id: this.idEstruturaUsuario,
        nomEstruturaUsuario: nome,
        indAtivo: this.estruturaUsuario.indAtivo,
        indHierarquia: this.estruturaUsuario.indHierarquia,
        indRepresentante: this.estruturaUsuario.indRepresentante,
        indCarteira: this.estruturaUsuario.indCarteira,
        indRateio: this.estruturaUsuario.indRateio,
        tiposVerba: this.estruturaUsuario.tiposVerba,
        tiposAcao: this.estruturaUsuario.tiposAcao,
        visaoExtensao: this.estruturaUsuario.visaoExtensao,
        estruturaUsuarioPais: this.$refs.estruturaPai.getEstruturasPais(),
      };

      estrutura.indExtensao = !!(this.estruturaUsuario.visaoExtensao
        && this.estruturaUsuario.visaoExtensao.length);

      const paiVerbaEstrutural = estrutura.estruturaUsuarioPais.filter((e) => e.verbaEstrutural);
      if (paiVerbaEstrutural.length) {
        estrutura.idEstruturaUsuarioPai = paiVerbaEstrutural[0].id;
        estrutura.estruturaUsuarioPai = paiVerbaEstrutural[0].nomEstruturaUsuario;
      }
      return estrutura;
    },
    closeDialog(dialog, cb) {
      dialog.open = false;
      if (cb) {
        cb();
      }
    },
    requestAtualizar(object, resource) {
      const { id } = object;

      resource({ id }, object)
        .then(() => {
          this.voltar();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestGravar(object, resource) {
      resource(object)
        .then(() => {
          this.voltar();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    voltar() {
      this.$router.push({ name: 'estrutura-usuario' });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.idEstruturaUsuario = parseInt(this.$route.params.id, 10);
      this.carregarEstrutura();
    }
    this.popularMetadados();
    this.buscarTiposAcao();
  },
};
</script>
<style>
  .v-select__selections {
    min-height: 32px !important;
  }

  .v-chip .v-chip__content {
    height: 22px;
  }
</style>
